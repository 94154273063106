<template>
  <span>
    <a v-if="modules && !!modules['meter_data']" href="#" @click.prevent="utilityMeters(unitId)"><fa-icon icon="chart-area" />Utility Meters</a>
    <b-modal ref="utilitymodal" size="lg" no-enforce-focus hide-header>
      <b-row>
        <b-col cols="8">
          <b-button-toolbar class="float-right" key-nav>
            <b-button-group>
              <b-button variant="success" @click="newUtilityMeter">
                <fa-icon icon="plus" /> New
              </b-button>
            </b-button-group>
          </b-button-toolbar>
          <b-table
            empty-html="No items to show" :fields="utilityMeterFields" :items="utilitymeters" small responsive striped
            hover show-empty
          >
            <template v-slot:cell(typeId)="{ item }">
              {{ item.type.name }} <span v-if="item.type.unit">({{ item.type.unit }})</span>
            </template>
            <template v-slot:cell(nodeId)="{ item }">
              <b-badge v-if="(item.deviceId && item.nodeId)" variant="success">yes</b-badge>
              <b-badge v-if="!(item.deviceId && item.nodeId)" variant="danger">no</b-badge>
            </template>
            <template v-slot:cell(deviceId)="{ item }">
              <span v-if="item.device" variant="success">{{ item.device.name }}</span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <a href="#" class="text-danger" title="Delete" @click.prevent="removeUtilityMeter(item)"><fa-icon icon="trash" /></a>
            </template>
          </b-table>
        </b-col>
        <b-col v-if="newEditUtilityMeter">
          <form @submit.prevent="saveUtilityMeter">
            <b-form-group label="Name">
              <b-form-input id="name" v-model="utilitymeter.name" type="text" />
            </b-form-group>
            <b-form-group label="Type" label-for="typeId">
              <blueprint-search-input
                id="typeId"
                :key="utilitymeter.typeId"
                v-model="utilitymeter.typeId"
                :state="state('typeId')"
                placeholder="Type to search"
                api-route-path="inventory/utilitymetertypes"
              />
            </b-form-group>
            <!-- @IDEA: use larva-device module component ? --->
            <a v-if="(modules && !!modules['larva_devices']) && !showUtilityMeterDevice" href="#" @click.prevent="showUtilityMeterDevice = true">Automatic readouts</a>
            <b-form-group v-if="showUtilityMeterDevice" label="Device" label-for="deviceId">
              <blueprint-search-input
                id="deviceId"
                :key="utilitymeter.deviceId"
                v-model="utilitymeter.deviceId"
                :state="state('deviceId')"
                placeholder="Type to search"
                :api-route-path="`inventory/units/${unitId}/devices`"
                @input="getDeviceUtilityMeters"
              />
            </b-form-group>
            <b-form-group v-if="showUtilityMeterDevice && utilitymeter.deviceId && deviceUtilityMeters && deviceUtilityMeters.length > 0" label="Device Utility Meter">
              <b-form-select v-model="utilitymeter.nodeId" :state="state('nodeId')">
                <option v-for="node in deviceUtilityMeters" :key="node.node.id" :value="node.node.id">{{ node.ui.name }} ({{ node.node.id }})</option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="showUtilityMeterDevice && utilitymeter.deviceId && deviceUtilityMeters && deviceUtilityMeters.length === 0" label="Device Utility Meter">
              <b-form-input id="nodeId" v-model="utilitymeter.nodeId" :state="state('nodeId')" type="text" />
            </b-form-group>
            <div class="text-center">
              <b-button variant="success" type="submit">Save</b-button>
            </div>
          </form>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">Close</b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';

library.add(faChartArea);

export default {
  props: ['unitId'],
  data () {
    return {
      errors: [],
      utilitymeter: { },
      utilitymeters: [],
      deviceUtilityMeters: [],
      newEditUtilityMeter: false,
      showUtilityMeterDevice: false,
      utilityMeterFields: [
        {
          key: 'name',
        },
        {
          label: 'Type',
          key: 'typeId',
        },
        {
          label: 'Automatic readouts',
          key: 'nodeId',
        },
        {
          label: 'Device',
          key: 'deviceId',
        },
        {
          key: 'actions',
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    getDeviceUtilityMeters () {
      if (this.unitId && this.utilitymeter && this.utilitymeter.deviceId) {
        this.$http.get(`inventory/units/${this.unitId}/devices/${this.utilitymeter.deviceId}/utilitymeters`)
          .then((res) => {
            this.deviceUtilityMeters = res.data || [];
            this.utilitymeter = this.utilitymeter || {};
            this.utilitymeter.nodeId = this.deviceUtilityMeters.length > 0 ? this.deviceUtilityMeters[0].id : undefined;
          })
          .catch(this.err);
      } else {
        this.deviceUtilityMeters = [];
      }
    },
    utilityMeters () {
      this.getUtilityMeters()
        .then(() => { this.$refs.utilitymodal.show(); });
    },
    newUtilityMeter () {
      this.utilitymeter = { };
      this.newEditUtilityMeter = true;
    },
    getUtilityMeters () {
      return this.$http.get(`inventory/units/${this.unitId}/utilitymeters`)
        .then((res) => {
          this.utilitymeters = res.data || [];
          this.errors = {};
        })
        .catch(this.err);
    },
    saveUtilityMeter () {
      this.$http.post(`inventory/units/${this.unitId}/utilitymeters`, this.utilitymeter)
        .then(() => {
          this.newEditUtilityMeter = false;
          this.showUtilityMeterDevice = false;
        })
        .then(() => this.getUtilityMeters())
        .catch(this.err);
    },
    removeUtilityMeter (item) {
      const { id } = item;
      const url = `inventory/units/${this.unitId}/utilitymeters/${id}`;
      this.$http.delete(url)
        .then(() => this.getUtilityMeters())
        .catch(this.err);
    },
  },
};
</script>
