<template>
  <span>
    <a v-if="modules && !!modules['larva_devices']" href="#" @click.prevent="accesses(unitId)"><fa-icon icon="users" />Access List</a>
    <b-modal ref="aclmodal" size="lg" no-enforce-focus hide-header>
      <b-table
        empty-html="Nobody has access to this unit" :fields="accessRulesFields" :items="accessrules" small striped hover
        show-empty
      >
        <template v-slot:cell(rule)="{ item }">
          <span v-if="item.groupId"><b-badge variant="primary">Group</b-badge>&nbsp;<b-badge variant="danger">{{ item.groupName }}</b-badge><br></span>
          <span v-if="item.role"><b-badge variant="primary">Role</b-badge>&nbsp;<b-badge variant="danger">{{ item.role }}</b-badge><br></span>
          <span v-if="item.bookingId"><b-badge variant="primary">Booking</b-badge>&nbsp;<b-badge variant="warning">{{ item.bookingNumber }}</b-badge><br></span>
          <span v-if="item.serviceId"><b-badge variant="primary">Service</b-badge>&nbsp;<b-badge variant="warning">{{ item.serviceName }}</b-badge><br></span>
        </template>
        <template v-slot:cell(type)="{ item }">
          <b-badge variant="primary">{{ item.type }} </b-badge>
        </template>
        <template v-slot:cell(period)="{ item }">
          <span v-if="item.period">{{ item.period.start | moment('LL') }} - {{ item.period.end | moment('LL') }}</span>
          <span v-else>-</span>
        </template>
      </b-table>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">Close</b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

library.add(faUsers);

export default {
  props: ['unitId'],
  data () {
    return {
      errors: [],
      accessrules: [],
      accessRulesFields: [
        /*
        {
          key: 'id',
        },
        */
        {
          key: 'type',
        },
        {
          key: 'firstname',
        },
        {
          key: 'lastname',
        },
        {
          key: 'email',
        },
        {
          key: 'phone',
        },
        {
          key: 'period',
        },
        {
          key: 'rule',
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    accesses (unitId) {
      this.$http.get(`inventory/units/${unitId}/accesslist`)
        .then((res) => { // eslint-disable-line
          this.accessrules = res.data;
          this.$refs.aclmodal.show();
        })
        .catch(this.err);
    },
  },
};
</script>
