<template>
  <div>
    <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
      <blueprint-search-input
        id="properties"
        v-model="properties"
        :multiple="true"
        placeholder="Type to search Properties"
        label="name"
        track-by="id"
        api-route-path="inventory/properties"
      />
    </b-form-group>
    <b-form-group v-if="noTypes === undefined" label-cols-sm="4" label-cols-lg="3" label="Types">
      <blueprint-search-input
        id="types"
        v-model="types"
        :multiple="true"
        placeholder="Type to search Type"
        label="name"
        track-by="id"
        api-route-path="inventory/types"
      />
    </b-form-group>
    <b-form-group v-if="noDevices === undefined" label-cols-sm="4" label-cols-lg="3" label="Devices">
      <blueprint-search-input
        id="devices"
        v-model="devices"
        :multiple="true"
        placeholder="Type to search Device"
        label="name"
        track-by="id"
        api-route-path="inventory/devices"
      />
    </b-form-group>
    <b-form-group v-if="noStatus === undefined" label-cols-sm="4" label-cols-lg="3" label="Status">
      <b-form-select v-model="status" :options="statuses" />
    </b-form-group>
  </div>
</template>

<style lang="sass" scoped>

</style>

<script>
export default {
  props: ['value', 'noDevices', 'noTypes', 'noStatus'],
  data () {
    return {
      statuses: [
        { value: '', text: '' },
        { value: 'ENABLED', text: 'Enabled' },
        { value: 'DISABLED', text: 'Disabled / Out of Order' },
      ],
      properties: [],
      types: [],
      amenities: [],
      devices: [],
      status: '',
    };
  },
  watch: {
    properties () {
      this.buildWhere();
    },
    types () {
      this.buildWhere();
    },
    amenities () {
      this.buildWhere();
    },
    devices () {
      this.buildWhere();
    },
    status () {
      this.buildWhere();
    },
  },
  methods: {
    parse (name, arr) {
      const ret = {};
      if (Array.isArray(arr) && arr.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        ret[name] = arr[0];
      } else if (Array.isArray(arr) && arr.length > 1) {
        ret[name] = { $in: arr };
      }
      return ret;
    },

    buildWhere () {
      const {
        properties, types, devices,
      } = this;

      const where = {
        ...this.parse('propertyId', properties),
        ...this.parse('typeId', types),
        ...this.parse('devices.id', devices),
        ...(this.status !== '' ? { status: this.status } : {}),
      };

      this.$emit('input', where);
    },
  },
};
</script>
