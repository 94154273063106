<template>
  <div class="row">
    <div class="col">
      <b-card title="Units">
        <blueprint-data api-route-path="inventory/units" :fields="fields" :new-default-values="newDefaultValues" :where="where" :q="q">
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Name">
              <b-form-input id="name" v-model="q" type="text" placeholder="Search..." />
            </b-form-group>
            <units-filter v-model="where" />
          </template>
          <template v-slot:actions="{ item }">
            <DevicesLink :unit-id="item.id" />
            <MeterDataLink :unit-id="item.id" />
            <AccessListLink :unit-id="item.id" />
          </template>
          <template v-slot:propertyId="{ item }">
            {{ item.property.name }}
          </template>
          <template v-slot:typeId="{ item }">
            {{ item.type.name }}
          </template>
          <template v-slot:area="{ item }">
            {{ item.area }}m<sup>2</sup>
          </template>
          <template v-slot:cubage="{ item }">
            {{ item.area }}m<sup>3</sup>
          </template>
          <template v-slot:status="{ item }">
            <b-badge v-if="item.status === 'ENABLED'" variant="success">
              Enabled
            </b-badge>
            <b-badge v-if="item.status !== 'ENABLED'" variant="danger">
              Disabled / Out of order
            </b-badge>
          </template>
          <template v-slot:default="{ state, item }">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Area" label-for="area">
              <b-form-input v-model="item.area" :state="state('area')" type="text" placeholder="Area in m2" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Width" label-for="width">
              <b-form-input v-model="item.width" :state="state('width')" type="text" placeholder="Width in m" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Height" label-for="height">
              <b-form-input v-model="item.height" :state="state('height')" type="text" placeholder="Height in m" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Length" label-for="length">
              <b-form-input v-model="item.length" :state="state('length')" type="text" placeholder="length in m" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Cubage" label-for="cubage">
              <b-form-input v-model="item.cubage" :state="state('cubage')" type="text" placeholder="Cubage in m3" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Property" label-for="propertyId">
              <blueprint-search-input
                id="propertyId"
                v-model="item.propertyId"
                placeholder="Type to search"
                api-route-path="inventory/properties"
                :state="state('propertyId')"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Type" label-for="typeId">
              <blueprint-search-input
                id="typeId"
                v-model="item.typeId"
                placeholder="Type to search"
                api-route-path="inventory/types"
                :state="state('typeId')"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Status" label-for="status">
              <b-form-select id="status" v-model="item.status" :state="state('status')" :options="statuses" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import UnitsFilter from '../../../components/units-filter.vue';
import MeterDataLink from '../../../service-modules/meter_data/components/units-utility-meters-link.vue';
import DevicesLink from '../../../service-modules/larva_devices/components/unit-devices-link.vue';
import AccessListLink from '../../../service-modules/larva_devices/components/unit-access-list-link.vue';

export default {
  components: {
    UnitsFilter,
    MeterDataLink,
    DevicesLink,
    AccessListLink,
  },
  data () {
    return {
      unitId: '',
      newDefaultValues: {
        status: 'DISABLED',
      },
      where: {},
      q: null,
      statuses: [
        { value: 'ENABLED', text: 'Enabled' },
        { value: 'DISABLED', text: 'Disabled / Out of Order' },
      ],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'area',
          sortable: true,
          slot: true
        },
        {
          key: 'width',
          sortable: true,
        },
        {
          key: 'height',
          sortable: true,
        },
        {
          key: 'length',
          sortable: true,
        },
        {
          key: 'cubage',
          sortable: true,
          slot: true
        },
        {
          key: 'typeId',
          label: 'Unit Type',
          sortable: true,
          slot: true,
        },
        {
          key: 'propertyId',
          label: 'Property',
          sortable: true,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },
      ],
    };
  }
};
</script>
