<template>
  <span>
    <a v-if="modules && !!modules['larva_devices']" href="#" @click.prevent="devices(unitId)"><fa-icon icon="microchip" />Devices</a>
    <b-modal ref="devicesmodal" no-enforce-focus hide-header>
      <form @submit.prevent="saveDevices">
        <b-form-group label="Devices" label-for="devices">
          <blueprint-search-input
            id="devices"
            v-model="devicesData"
            placeholder="Type to search"
            api-route-path="inventory/devices"
            :state="state('devices')"
            :multiple="true"
          />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="saveDevices()">Save</b-button>
        <b-button variant="dark" @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';

library.add(faMicrochip);

export default {
  props: ['unitId'],
  data () {
    return {
      errors: [],
      devicesData: [],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    devices (unitId) {
      this.devicesData = [];
      this.$http.get(`inventory/units/${unitId}/devices`)
        .then((res) => {
          this.devicesData = res.data;
          this.errors = {};
          this.$refs.devicesmodal.show();
        })
        .catch(this.err);
    },
    saveDevices () {
      this.$http.patch(`inventory/units/${this.unitId}/devices`, this.devicesData || [])
        .then(() => {
          this.$refs.devicesmodal.hide();
        })
        .catch(this.err);
    },
  },
};
</script>
